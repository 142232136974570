import React from 'react';
import './About.scss';
import Navbar1 from "./Navbar1";
import Footer from "./Footer";
import {phoneCheck} from "./helpers/browserCheck";

const About = () => {
    return(
        <div className="About">
            <Navbar1/>
            <div className={phoneCheck() ? "about-container-mobile" : "about-container"}>
                <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699224581/IMG_6522_nbywcu.jpg"/>
                <div className="about-copy-container">
                    <p>Natasha Desai is an independent photographer and educator based in Mumbai. Her repertoire includes behind-the-scenes, events, portraits, street, and fine art photography and her work has been featured in Better Photography, Verve, Hindustan Times, and TimeOut Mumbai.</p>
                    <p>She previously worked at Better Photography, where she published over 50 images and authored more than 100 feature stories. Since 2021, she has been teaching photography to undergraduate and postgraduate students at Sophia College.</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default About;
